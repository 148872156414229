import logo from "./logo.svg";
import Hero from "./components/Hero";
import MainContent from "./components/MainContent";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Hero></Hero>
      <MainContent></MainContent>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;
